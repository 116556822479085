import "./App.css";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import BoardList from "./page/board_list";
import Missing from "./page/error_page";
import Split from "./page/board_split";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/community" element={<Split />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;

import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import classNames from "classnames";
import RelativeTime from "./RelativeTime";
import ReComment from "./board_recomment";
import { Query } from "react-query";

const Commnet = ({ post_id, id_check, getDetail, comment_count, comment_data, set_comment_data, user_name }) => {
  const [comment_text, set_comment_text] = useState("");
  const fileRef = useRef();
  const [fileImage, setFileImage] = useState("");
  const [ImageView, set_ImageView] = useState("");
  const [comment_sort, setcomment_sort] = useState("popular");
  const [sliceCount, setsliceCount] = useState(10);
  const [commentID, setcommentID] = useState(-1);
  const local_user_no = parseInt(localStorage.getItem("user_no"));

  const saveFileImage = (e) => {
    if (e.target.files[0] === undefined) {
      set_ImageView("");
    } else {
      setFileImage(e.target.files[0]);
      set_ImageView(URL.createObjectURL(e.target?.files[0]));
    }
  };

  const deleteFileImage = () => {
    URL.revokeObjectURL(ImageView);
    set_ImageView("");
    setFileImage("");
    fileRef.current.value = "";
  };

  const get_comment = () => {
    if (comment_sort === "new") {
      set_comment_data(comment_data.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)));
    } else {
      const arr = comment_data.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)); // 내림차순으로 안해주면 조회수 정렬이지만 오름차순으로 정렬
      set_comment_data(arr.sort((a, b) => b.cmt_like - a.cmt_like));
    }
    // console.log("댓글", res.data);
    set_comment_text("");
  };

  const comment_handle = (e) => {
    set_comment_text(e.target.value);
  };

  const imgbtn = () => {
    fileRef.current.click();
  };

  //   const name = `<span class=recommnet_id>@${user_name}</span>`;
  const commentbtn = async (v) => {
    if (fileImage) {
      const formData = new FormData();
      formData.append("imgFile", fileImage, fileImage.name);
      const response = await axios.post(process.env.REACT_APP_UPLOAD_URL + "upload", formData, {
        headers: {
          id: local_user_no,
        },
      });
      const comment_content = {
        content: `${comment_text}<p><img src="${process.env.REACT_APP_IMGURL + response.data.file_server}"></p>`,
        id: local_user_no,
        name: user_name,
      };
      axios.post(process.env.REACT_APP_SERVER_URL + "/post/comment/" + post_id, comment_content).then((res) => {
        if (res.data.message) {
          alert(res.data.message);
        } else {
          console.log(res.data);
          getDetail();
          deleteFileImage();
          set_comment_text("");
          //   get_comment();
        }
      });
    } else {
      const comment_content = {
        content: comment_text,
        id: local_user_no,
        name: user_name,
      };
      axios.post(process.env.REACT_APP_SERVER_URL + "/post/comment/" + post_id, comment_content).then((res) => {
        if (res.data.message) {
          alert(res.data.message);
        } else {
          // console.log(res.data);
          getDetail();
          set_comment_text("");
          //   get_comment();
        }
      });
    }
  };
  const new_sort_btn = () => {
    setcomment_sort("new");
    const newArray = comment_data.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
    set_comment_data(newArray);
  };
  const popular_sort_btn = () => {
    setcomment_sort("popular");

    const newArray = comment_data.sort((a, b) => b.cmt_like - a.cmt_like);
    console.log(newArray);
    set_comment_data(newArray);
  };
  const moreview = () => {
    setsliceCount(sliceCount + 10);
  };
  const reButton = (id) => {
    if (id === commentID) {
      setcommentID(-1);
    } else {
      setcommentID(id);
    }
  };
  const delete_btn = async (valueid) => {
    if (window.confirm("정말 삭제하시겠습니까?")) {
      // console.log("삭제", valueid);
      await axios.delete(process.env.REACT_APP_SERVER_URL + "/post/comment/" + valueid + `?id=${local_user_no}`);
      // get_comment();
      getDetail();
    } else {
      return;
    }
  };
  return (
    <div>
      <div className="board-reply">
        <div className="reply-header">
          <strong className="title">댓글</strong>총 <span className="f-diti">{comment_count}</span>개
        </div>
        <div className="reply-write">
          <textarea className="rep" placeholder="댓글을 입력해주세요" value={comment_text} onChange={comment_handle}></textarea>
          <div className="write-foot">
            <div className="write-file">
              <input
                className="file-input"
                id="reply_file"
                ref={fileRef}
                type="file"
                accept="image/jpeg , image/png, image/gif"
                style={{ display: "none" }}
                onChange={saveFileImage}
              />
              {ImageView ? (
                <div className="image_file_name">
                  {ImageView && <img alt="sample" src={ImageView} className="preview" />}
                  <div className="comment_image_name">{fileImage.name}</div>
                  <div onClick={deleteFileImage} className="delete_image">
                    {" "}
                    x
                  </div>
                </div>
              ) : (
                <button className="comment_image_upload_btn" onClick={imgbtn}>
                  <i className="far fa-image"></i>
                  {/* <Translate keyname="community-detail-comment-input-image" /> */}
                </button>
              )}
              <button onClick={commentbtn} className="comment_submit_btn">
                등록
              </button>
            </div>
          </div>
        </div>
      </div>
      {comment_data === undefined || comment_data.length === 0 ? (
        <div>{""}</div>
      ) : (
        <div className="commnet_sort">
          <button
            className={classNames("sort_btn", {
              sorton: comment_sort === "popular",
            })}
            onClick={popular_sort_btn}
          >
            추천순
          </button>
          <button
            className={classNames("sort_btn", {
              sorton: comment_sort === "new",
            })}
            onClick={new_sort_btn}
          >
            최신순
          </button>
        </div>
      )}
      {comment_data?.slice(0, sliceCount).map((item, index) => (
        <div className="comment_box" key={index}>
          <div className="comment_topWrap">
            <div>
              <span style={{ fontWeight: "bold", fontSize: "14.3px" }}>{item.user_name}</span>
              <span className="diver"></span>
              <span style={{ color: "rgba(136, 136, 136)", fontSize: "13px" }}>{RelativeTime(item.createdAt)}</span>
              {item.user_id === local_user_no ? <span className="diver"></span> : ""}
              {item.user_id === local_user_no ? (
                <button onClick={() => delete_btn(item.cmt_id)}>
                  <i className="far fa-trash-alt"></i> 삭제
                </button>
              ) : (
                ""
              )}
            </div>
            {/* <CommnetLike Like_count={item.cmt_like} disLike_count={item.cmt_dislike} cmt_id={item.cmt_id} get_comment={get_comment} /> */}
          </div>
          <div className="comment_wrap" dangerouslySetInnerHTML={{ __html: item.cmt_content }}></div>
          <div className="comment_bottomWrap">
            <span className="recomment" onClick={() => reButton(item.cmt_id)}>
              <i className="far fa-comments"></i> 답글쓰기
            </span>
          </div>
          {commentID === item.cmt_id ? (
            <ReComment
              post_id={post_id}
              cmt_id={item.cmt_id}
              user_name={item.user_name}
              get_comment={get_comment}
              setcommentID={setcommentID}
              getDetail={getDetail}
            />
          ) : (
            ""
          )}
          {item.children.sort().map((subitem, index) => (
            <div className="recomment_box" key={index}>
              <div className="recomment_topWrap">
                <div>
                  <span style={{ fontWeight: "bold", fontSize: "14.3px" }}>{subitem.user_name}</span>
                  <span className="diver"></span>
                  <span className="time">{RelativeTime(subitem.createdAt)}</span>
                  {item.user_id === local_user_no ? <span className="diver"></span> : ""}
                  {item.user_id === local_user_no ? (
                    <button onClick={() => delete_btn(subitem.cmt_id)}>
                      <i className="far fa-trash-alt"></i> 삭제
                    </button>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <div className="comment_wrap" dangerouslySetInnerHTML={{ __html: subitem.cmt_content }}></div>
              <div className="comment_bottomWrap">
                <span className="recomment" onClick={() => reButton(subitem.cmt_id)}>
                  <i className="far fa-comments"></i> 답글쓰기
                </span>
                {subitem.user_id === id_check?.id ? <span className="diver"></span> : ""}
                {subitem.user_id === id_check?.id ? (
                  <button onClick={() => delete_btn(subitem.cmt_id)}>
                    <i className="far fa-trash-alt"></i>
                  </button>
                ) : (
                  ""
                )}
              </div>
              {commentID === subitem.cmt_id ? (
                <ReComment
                  post_id={post_id}
                  cmt_id={item.cmt_id}
                  user_name={subitem.user_name}
                  get_comment={get_comment}
                  setcommentID={setcommentID}
                  getDetail={getDetail}
                />
              ) : (
                ""
              )}
            </div>
          ))}
        </div>
      ))}
      {comment_data.length > sliceCount ? (
        <div className="more_Comment" onClick={moreview}>
          asdsd
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default Commnet;

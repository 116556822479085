import React, { useEffect, useState } from "react";
import QEditor from "../components/editor/quill_editor";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import replace_All from "../components/common";
import queryString from "query-string";
import useSearchParams from "../components/useSearchParams";

const BoardWrite = () => {
  const location = useLocation(); //바뀐 부분
  const query = queryString.parse(location.search);
  const navigate = useNavigate();
  const [title, settitle] = useState("");
  const [content, setcontent] = useState("");
  const [deleteList, setdeleteList] = useState([]);
  const [post_video, setpost_video] = useState("");
  const { searchParams, withSearchParams } = useSearchParams();
  const local_user_no = parseInt(localStorage.getItem("user_no"));
  const submitButton = () => {
    const imgregExp = /<img[^>]*src="[\"']?([^>\"']+)[\"']?[^>]*>/i; // 2022-03-17 src=에서 src="으로 수정 src주소에 쿼리 파라미터를 src를 해놓는곳이 있어서 변경
    const thumbnail_imgUrl = content.match(imgregExp) ? content.match(imgregExp)[1] : "";
    if (title === "") {
      return alert("제목을 입력해주세요");
    } else if (content === "") {
      return alert("내용을 입력해주세요");
    } else if (title.length > 50) {
      return alert("제목이 너무 깁니다.");
    }
    axios
      .post(process.env.REACT_APP_SERVER_URL + "/post", {
        brd_id: query.brd_id,
        id: local_user_no,
        name: query.user_name,
        title: title,
        content: content,
        video: post_video.includes("https://youtu.be") ? post_video : "",
        thumbnail: replace_All(thumbnail_imgUrl, "&amp;", "&"),
      })
      .then((res) => {
        // console.log("글쓰기성공", res.data);
        // setleave(false);
        navigate(
          withSearchParams(`/community`, {
            set: { page: 1 },
            remove: ["keyword", "mode"],
          })
        );
      });
  };

  const title_handler = (e) => {
    settitle(e.target.value);
  };

  const handleprompt = (location, action) => {
    // console.log(action);
    if (action === "POP" || action === "PUSH") {
      if (window.confirm("글 작성을 취소하시겠습니까?")) {
        // delete_image();
        return true;
      } else {
        return false;
      }
    }
  };

  useEffect(() => {
    check();
  }, []);

  const check = () => {
    if (query.user_id === "guest") {
      alert("글쓰기는 회원 전용입니다");
      navigate(-1);
    }
  };

  return (
    <>
      <div className="write_page">
        <div className="board_write">
          <div className="Write_input_wrap">
            <input onChange={title_handler} placeholder="제목을 입력해주세요"></input>
          </div>
          <QEditor content={content} setcontent={setcontent} setdeleteList={setdeleteList} deleteList={deleteList} local_user_no={local_user_no} />
          <div className="Write_button_wrap">
            <button onClick={() => navigate(-1)}>취소</button>
            <button onClick={submitButton} className="write_submit">
              <i className="fas fa-pen"></i> 등록
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default BoardWrite;

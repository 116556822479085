import React, { useEffect, useState } from "react";
import queryString from "query-string";
import { useLocation } from "react-router-dom";
import BoardList from "./board_list";
import BoardDetail from "./board_detail";
import BoardWrite from "./board_write";
import BoardEdit from "./board_edit";
import axios from "axios";

const Split = () => {
  const location = useLocation(); //바뀐 부분
  const query = queryString.parse(location.search);
  const [loading, setloading] = useState(false);

  const Get_id = async () => {
    try {
      const { data } = await axios.post(process.env.REACT_APP_SERVER_URL + "/user", { id: query.user_id });
      // console.log(data.user.user_no);
      window.localStorage.setItem("user_no", data?.user?.user_no);
      setloading(true);
    } catch (error) {
      alert(error);
    }
  };

  useEffect(() => {
    Get_id();
  }, []);
  if (loading) {
    if (query.mode === "read") {
      return <BoardDetail />;
    } else if (query.mode === "write") {
      return <BoardWrite />;
    } else if (query.mode === "edit") {
      return <BoardEdit />;
    } else if (query.mode === undefined) {
      return (
        <>
          <BoardList />
        </>
      );
    }
  } else {
    <div>로딩중..</div>;
  }
};

export default Split;

import React, { useState, useRef } from "react";
import axios from "axios";
import queryString from "query-string";
import { Link, useLocation, useNavigate } from "react-router-dom";

const ReComment = ({ cmt_id, post_id, get_comment, setcommentID, user_name, getDetail }) => {
  const fileRef = useRef();
  const location = useLocation(); //바뀐 부분
  const query = queryString.parse(location.search);
  const [fileImage, setFileImage] = useState("");
  const [ImageView, set_ImageView] = useState("");
  const [recomment_text, set_recomment_text] = useState("");
  const local_user_no = parseInt(localStorage.getItem("user_no"));

  const saveFileImage = (e) => {
    if (e.target.files[0] === undefined) {
      set_ImageView("");
    } else {
      setFileImage(e.target.files[0]);
      set_ImageView(URL.createObjectURL(e.target?.files[0]));
    }
  };

  const deleteFileImage = () => {
    URL.revokeObjectURL(ImageView);
    set_ImageView("");
    setFileImage("");
    fileRef.current.value = "";
  };

  const recomment_handle = (e) => {
    set_recomment_text(e.target.value);
  };

  const name = `<span class=recommnet_id>@${user_name}</span>`;

  const recommentbtn = async (v) => {
    if (fileImage) {
      const formData = new FormData();
      formData.append("imgFile", fileImage, fileImage.name);
      const response = await axios.post(process.env.REACT_APP_SERVER_URL + "/upload", formData, {
        headers: {
          id: 123,
        },
      });
      const recomment_content = {
        content:
          user_name === undefined
            ? `${recomment_text}<p><img src="${process.env.REACT_APP_IMGURL + response.data.file_server}"></p>`
            : `${name + recomment_text}<p><img src="${process.env.REACT_APP_IMGURL + response.data.file_server}"></p>`,
        parent: v,
        id: local_user_no,
        name: query.user_name,
      };
      axios.post(process.env.REACT_APP_SERVER_URL + "/post/comment/" + post_id, recomment_content).then((res) => {
        if (res.data.message) {
          alert(res.data.message);
        } else {
          // console.log(res.data);
          deleteFileImage();
          //   get_comment();
          set_recomment_text("");
          setcommentID(1);
          getDetail();
        }
      });
    } else {
      const recomment_content = {
        content: user_name === undefined ? recomment_text : name + recomment_text,
        parent: v,
        id: local_user_no,
        name: query.user_name,
      };
      axios.post(process.env.REACT_APP_SERVER_URL + "/post/comment/" + +post_id, recomment_content).then((res) => {
        if (res.data.message) {
          alert("asdasdasd");
        } else {
          // console.log(res.data);
          //   get_comment();
          set_recomment_text("");
          setcommentID(1);
          getDetail();
        }
      });
    }
  };
  const imgbtn = () => {
    fileRef.current.click();
  };

  return (
    <div className="board-reply re">
      <div className="reply-write">
        <textarea className="rep" placeholder="답글을 입력해주세요" value={recomment_text} onChange={recomment_handle}></textarea>
        <div className="write-foot">
          <div className="write-file">
            <input
              className="file-input"
              ref={fileRef}
              type="file"
              accept="image/jpeg , image/png, image/gif"
              style={{ display: "none" }}
              onChange={saveFileImage}
            />
            {ImageView ? (
              <div className="image_file_name">
                {ImageView && <img alt="sample" src={ImageView} className="preview" />}
                <span className="comment_image_name">{fileImage.name}</span>
                <span onClick={deleteFileImage} className="delete_image">
                  {" "}
                  x
                </span>
              </div>
            ) : (
              <button className="comment_image_upload_btn" onClick={imgbtn}>
                <i className="far fa-image"></i>
                {/* <Translate keyname="community-detail-comment-input-image" /> */}
              </button>
            )}
            <button className="comment_submit_btn" onClick={() => recommentbtn(cmt_id)}>
              답글
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReComment;

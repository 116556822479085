import axios from "axios";
import queryString from "query-string";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import QEditor from "../components/editor/quill_editor";

const BoardEdit = () => {
  const location = useLocation(); //바뀐 부분
  const query = queryString.parse(location.search);
  const navigate = useNavigate();
  const [title, settitle] = useState("");
  const [content, setcontent] = useState("");
  const [post_video, setpost_video] = useState("");
  const [deleteList, setdeleteList] = useState([]);
  const local_user_no = localStorage.getItem("user_no");
  const getcontents = async () => {
    try {
      const { data } = await axios.get(process.env.REACT_APP_SERVER_URL + "/post/" + query.post_id);
      if (local_user_no === data.user_id) {
        alert("아이디 불일치");
        return;
      } else {
        settitle(data.post_title);
        setcontent(data.post_content);
      }
    } catch (error) {
      alert(error);
    }
  };
  useEffect(() => {
    getcontents();
  }, []);
  const title_handler = (e) => {
    settitle(e.target.value);
  };

  const delete_image = () => {
    if (deleteList[0] !== undefined) {
      console.log("이미지 업로드된거 지우기", deleteList[0]);
      for (var i = 0; i < deleteList.length; i++) {
        var id = deleteList[i].file_id;
        axios.delete(process.env.REACT_APP_UPLOAD_URL + "upload/" + id + "?id=" + local_user_no).then((res) => {
          console.log(res);
        });
      }
    }
  };

  const cancel_btn = () => {
    delete_image();
    navigate(-1);
  };
  const submitButton = () => {
    const imgregExp = /<img[^>]*src="[\"']?([^>\"']+)[\"']?[^>]*>/i; // 2022-03-17 src=에서 src="으로 수정 src주소에 쿼리 파라미터를 src를 해놓는곳이 있어서 변경
    const thumbnail_imgUrl = content.match(imgregExp) ? content.match(imgregExp)[1] : "";
    if (title === "") {
      return alert("제목을 입력해주세요");
    } else if (content === "") {
      return alert("내용을 입력해주세요");
    } else if (title.length > 50) {
      return alert("글자제목이 너무 깁니다");
    }
    axios
      .put(process.env.REACT_APP_SERVER_URL + "/post/" + query.post_id, {
        brd_id: query.brd_id,
        id: local_user_no,
        name: query.user_name,
        title: title,
        content: content,
        video: post_video.includes("https://youtu.be") ? post_video : "",
        thumbnail: thumbnail_imgUrl.replace("&amp;", "&"),
      })
      .then((res) => {
        alert("수정완료");
        navigate(-1);
      });
  };
  return (
    <>
      <div className="write_page">
        {/* <div className="community-section-title">
          <em>수정페이지</em>
        </div> */}
        <div className="board_write">
          <div className="Write_input_wrap">
            <input placeholder="제목을입력하세요" onChange={title_handler} value={title}></input>
          </div>
          <QEditor content={content} setcontent={setcontent} setdeleteList={setdeleteList} deleteList={deleteList} local_user_no={local_user_no}/>
          <div className="Write_button_wrap">
            <button onClick={cancel_btn}>취소</button>
            <button onClick={submitButton} className="write_submit">
              <i className="fas fa-pen"></i> 수정
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default BoardEdit;

import axios from "axios";
import React from "react";
import { useQuery, useQueryClient } from "react-query";

const Like = ({ id, likeCount, getDetail, dislikeCount }) => {
  const local_user_no = parseInt(localStorage.getItem("user_no"));
  // const queryClient = useQueryClient();
  // const { isLoading, error, data, isFetching } = useQuery(
  //   "Like_Data",
  //   async () => {
  //     const data = await axios.get(process.env.REACT_APP_SERVER_URL + "하/post/likes");
  //     return data;
  //   },
  //   {
  //     staleTime: 600000,
  //     cacheTime: 600000,
  //     placeholderData: { data: { likes: [], dislikes: [] } },
  //   }
  // );

  // const like = data?.data.likes.findIndex((itme) => itme.post_id === parseInt(id));

  const Like_btn = async () => {
    try {
      const response = await axios.post(process.env.REACT_APP_SERVER_URL + "/post/like/" + id, { id: local_user_no });
      // console.log("추천요청");
      getDetail();
    } catch (error) {
      if (error.response.status === 400) {
        // console.log(error);
        alert(error.response.data.message);
        // message.warning(message_text_like);
      } else if (error.response.status === 401) {
        // message.warning(message_text_login);
        alert("로그인이 필요한 서비스입니다");
      }
    }
    // queryClient.invalidateQueries("Data");
  };

  const disLike_btn = async () => {
    try {
      const response = await axios.post(process.env.REACT_APP_SERVER_URL + "/post/dislike/" + id, { id: local_user_no });
      console.log("비추천요청");
      getDetail();
    } catch (error) {
      // console.log(error.response.status);
      if (error.response.status === 400) {
        alert(error.response.data.message);
      } else if (error.response.status === 401) {
        alert("로그인이 필요한 서비스입니다");
      }
    }
    // queryClient.invalidateQueries("Data");
  };
  const like = -1;
  const dislike = -1;
  return (
    <div>
      <div className="view-footer">
        <div className="view-vote">
          <button onClick={Like_btn} type="button" className="btn good is-tip">
            {like === -1 ? <i className="far fa-thumbs-up"></i> : <i className="fas fa-thumbs-up"></i>}
            <span className="f-digit">{likeCount}</span>
          </button>
          <button type="button" onClick={disLike_btn} className="btn bad is-tip">
            {dislike === -1 ? <i className="far fa-thumbs-down"></i> : <i className="fas fa-thumbs-down"></i>}
            <span className="f-digit">{dislikeCount}</span>
          </button>
        </div>
        <div className="view-share">
          <span className="btn">{/* <i className="fas fa-share-alt"></i>공유 */}</span>
          <span className="btn">{/* <i className="fas fa-exclamation"></i>신고 */}</span>
        </div>
      </div>
    </div>
  );
};

export default Like;

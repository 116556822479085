import { createSlice } from "@reduxjs/toolkit";

const tokenstatus = sessionStorage.getItem("user_Token") ? true : false;

const loginStateSlice = createSlice({
  name: "loginState",
  initialState: {
    status: "asd",
  },
  reducers: {
    login: (state, action) => {
      console.log("asd", action);
    },
    logout: (state, action) => {

    },
  },
});

export const { login, logout } = loginStateSlice.actions;
export default loginStateSlice.reducer;

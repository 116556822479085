import React, { useState } from "react";
import useSearchParams from "./useSearchParams";
import { Link, useNavigate } from "react-router-dom";
import classNames from "classnames";
import { useQuery, useQueryClient } from "react-query";
import axios from "axios";
const Search_Wrap = ({ query }) => {
  //   console.log(query);

  const navigate = useNavigate();
  const [ScrollY, setScrollY] = useState(0); // window 의 pageYOffset값을 저장
  const { searchParams, withSearchParams } = useSearchParams();
  const [ScrollActive, setScrollActive] = useState(false);
  const [text, settext] = useState("");

  const { isLoading, error, data, isFetching } = useQuery(
    "board",
    async () => {
      const { data } = await axios.get(process.env.REACT_APP_SERVER_URL + `/board`);
      return data;
    },
    {
      staleTime: 50000,
      cacheTime: 10000,
      placeholderData: {},
    }
  );

  const handle = (e) => {
    settext(e.target.value);
  };
  const handleScroll = () => {
    if (ScrollY > 150) {
      setScrollY(window.pageYOffset);
      setScrollActive(true);
    } else {
      setScrollY(window.pageYOffset);
      setScrollActive(false);
    }
  };

  const search_btn = () => {
    // console.log(text);
    navigate(
      withSearchParams(`/community`, {
        set: { keyword: text, page: 1 },
        remove: ["sort"],
      })
    );
  };

  // const defalut_sort = "/community?" + "brd_id=" + query.brd_id;

  const defalut_sort = withSearchParams(`/community`, {
    set: { page: 1 },
    remove: ["sort"],
  });

  const read_sort = query.page
    ? {
        set: { sort: "post_read", page: 1 },
        // remove: ["page"],
      }
    : {
        set: { sort: "post_read", page: 1 },
      };

  const like_sort = query.page
    ? {
        set: { sort: "post_like", page: 1 },
        // remove: ["page"],
      }
    : {
        set: { sort: "post_like", page: 1 },
      };
  const KeyPress = (e) => {
    // console.log(e);
    if (e.key === "Enter") {
      search_btn();
    }
  };
  return (
    <>
      <div className="list_headerwrap">
        <div
          className="category-search"
          style={{
            boxShadow: ScrollActive ? "" : "0px 2px 5px 0px rgb(0 0 0 / 21%)",
          }}
        >
          <div className="categoryWrap">
            <Link to={defalut_sort}>
              <span className={classNames("wrap_sort", { red: query.sort === undefined })}>
                <i className="fas fa-history"></i> 최신
              </span>
            </Link>

            <Link to={withSearchParams(`/community`, like_sort)}>
              <span className={classNames("wrap_sort", { red: query.sort === "post_like" })}>
                <i className="far fa-thumbs-up"></i>
                추천수
              </span>
            </Link>

            <Link to={withSearchParams(`/community`, read_sort)}>
              <span className={classNames("wrap_sort", { red: query.sort === "post_read" })}>
                <i className="far fa-hand-point-up"></i> TOP
              </span>
            </Link>
          </div>
          <div className="list_search_wrap">
            <div className="searchbox">
              <input type="text" onKeyPress={KeyPress} onChange={handle} className="header_input" placeholder="search..." />
              <button onClick={search_btn} className="list_header_btn"></button>
            </div>
            {/* <Link to="/community?brd_id=1&mode=write"> */}
            {data[parseInt(query?.brd_id) - 1]?.brd_role === "admin" ? (
              ""
            ) : (
              <Link
                to={withSearchParams(`/community`, {
                  set: { mode: "write" },
                })}
              >
                <button className="Writebtn">
                  <i className="fas fa-pen"></i>
                  <span className="write_text">글쓰기</span>
                </button>
              </Link>
            )}
          </div>
        </div>
        {query.keyword ? <div className="search_result">{decodeURIComponent(query.keyword)}에 대한 검색결과입니다</div> : ""}
      </div>
    </>
  );
};

export default Search_Wrap;

import React, { useEffect, useState } from "react";
import queryString from "query-string";
import { Link, useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import dayjs from "dayjs";
import Like from "../components/Like";
import BoardList from "./board_list";
import Commnet from "../components/board_comment";
import useSearchParams from "../components/useSearchParams";
import { useQuery } from "react-query";
const BoardDetail = () => {
  const wrap_none = true;
  const location = useLocation(); //바뀐 부분
  const query = queryString.parse(location.search);
  const navigate = useNavigate();
  const { searchParams, withSearchParams } = useSearchParams();
  const [data, setdata] = useState({});
  const [comment_data, set_comment_data] = useState([]);
  const [loading, setloading] = useState(false);
  const local_user_no = parseInt(localStorage.getItem("user_no"));

  const getDetail = async () => {
    try {
      const { data } = await axios.get(`${process.env.REACT_APP_SERVER_URL}/post/${query.post_id}`);
      setdata(data);
      set_comment_data(data.comments);
      setloading(true);
    } catch (error) {}
  };

  useEffect(() => {
    getDetail();
    window.scrollTo(0, 0);
  }, [query.post_id]);

  const board_data = useQuery(
    "board",
    async () => {
      const { data } = await axios.get(process.env.REACT_APP_SERVER_URL + `/board`);
      return data;
    },
    {
      staleTime: 50000,
      cacheTime: 10000,
      placeholderData: {},
    }
  );

  const delete_btn = () => {
    if (window.confirm("정말 삭제하시겠습니까?")) {
      axios.delete(process.env.REACT_APP_SERVER_URL + "/post/" + query.post_id + "?id=" + local_user_no).then((res) => {
        navigate(withSearchParams(`/community`, { remove: ["mode"] }));
        alert("삭제완료");
      });
    }
  };
  const edit_btn = () => {
    navigate(withSearchParams(`/community`, { set: { mode: "edit" } }));
  };
  // console.log(board_data?.data[parseInt(query?.brd_id) - 1]?.brd_role);
  // const like = data?.data.likes.findIndex((itme) => itme.post_id === parseInt(id));
  return (
    <>
      <div className="board-detail">
        <div className="board-view">
          <div className="view-header">
            <h2 className="title">
              {data.post_notice ? <span className="notice_span">[공지]</span> : ""}
              {data.post_title}
            </h2>
            <div className="view-info">
              <div className="info">
                <span className="in">{dayjs(data.createdAt).format("YYYY-MM-DD")}</span>
                <span className="in">{data.user_name}</span>
              </div>
              <div className="info">
                <span className="in">
                  조회수 <strong className="f-digit">{data.post_read}</strong>
                </span>
                <span className="in">
                  댓글 <strong className="f-digit">{data.post_comment}</strong>
                </span>
                <span className="in">
                  추천 <strong className="f-digit">{data.post_like}</strong>
                </span>
              </div>
            </div>
          </div>
          <div className="inner_content" dangerouslySetInnerHTML={{ __html: data.post_content }}></div>
          <Like id={query.post_id} likeCount={data.post_like} dislikeCount={data.post_dislike} getDetail={getDetail} />
        </div>
        <div className="detail_bottom">
          <div className="delete_n_edit">
            {data.user_id === local_user_no ? (
              <div className="de">
                <button
                  onClick={() => {
                    navigate(
                      withSearchParams(`/community`, {
                        set: { page: 1 },
                        remove: ["mode", "keyword", "post_id", "page", "sort"],
                      })
                    );
                  }}
                >
                  <i className="fas fa-home"></i> 목록
                </button>
                {board_data?.data[parseInt(query?.brd_id) - 1]?.brd_role === "admin" || data.post_notice ? (
                  ""
                ) : (
                  <>
                    {" "}
                    <button onClick={delete_btn}>
                      <i className="far fa-trash-alt"></i> 삭제
                    </button>
                    <button onClick={edit_btn}>
                      <i className="fas fa-pen"></i> 수정
                    </button>
                  </>
                )}
              </div>
            ) : (
              <div className="de">
                <button
                  onClick={() => {
                    navigate(
                      withSearchParams(`/community`, {
                        remove: ["mode", "keyword", "post_id", "page", "sort"],
                      })
                    );
                  }}
                >
                  <i className="fas fa-home"></i>
                  목록
                </button>
              </div>
            )}
          </div>
          {board_data?.data[parseInt(query?.brd_id) - 1]?.brd_role === "admin" ? (
            ""
          ) : (
            <Commnet
              post_id={query.post_id}
              getDetail={getDetail}
              comment_count={data.post_comment}
              getComments={data.comments}
              comment_data={comment_data}
              set_comment_data={set_comment_data}
              user_id={local_user_no}
              user_name={query.user_name}
            />
          )}
          {loading ? <BoardList wrap_none={wrap_none} detail_id={data.post_id} read_count={data.post_read} /> : ""}
        </div>
      </div>
    </>
  );
};

export default BoardDetail;

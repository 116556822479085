import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import useSearchParams from "../components/useSearchParams";
import RelativeTime from "../components/RelativeTime";
import Pagination from "../components/Pagination";
import queryString from "query-string";
import Search_Wrap from "../components/serach_wrap";

const BoardList = ({ wrap_none, detail_id, read_count }) => {
  const navigate = useNavigate();
  const { searchParams, withSearchParams } = useSearchParams();
  const location = useLocation(); //바뀐 부분
  const query = queryString.parse(location.search);
  const [ListData, set_ListData] = useState([{}]);
  const [pageTotal, setpageTotal] = useState(0);
  const [currPage, setcurrPage] = useState(1);
  const list_size = 20;

  const getPage = (pageNUM) => {
    navigate(withSearchParams(`/community`, { set: { page: pageNUM } }));
    setcurrPage(parseInt(pageNUM));
  };

  const getList = async () => {
    try {
      const endPoint = query.keyword
        ? withSearchParams(`/search`, {
            set: {
              brd_id: query.brd_id,
              page: query.page,
              sort: query.sort,
              keyword: decodeURIComponent(query.keyword),
            },
          })
        : withSearchParams(``, {
            set: {
              brd_id: query.brd_id,
              page: query.page,
              sort: query.sort,
            },
          });
      const { data } = await axios.get(process.env.REACT_APP_SERVER_URL + "/post" + endPoint);
      set_ListData(data);
      setpageTotal(data.total);
    } catch (error) {
      alert("리스트 조회 오류");
    }
  };

  useEffect(() => {
    getList();
  }, [query.page, query.keyword, query.sort, detail_id, read_count]);
  // detail_id, read_count

  useEffect(() => {
    // console.log("페이징");
    if (query.page === undefined) {
      setcurrPage(1);
    } else {
      setcurrPage(parseInt(query.page));
    }
  }, [query.page]);

  const youturegEXP = (v) => {
    const regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
    const check = v.includes("youtu");
    if (check) {
      const match = v.match(regExp);
      const result = `http://img.youtube.com/vi/${match[7]}/0.jpg`;
      return result;
    } else {
      return `images/image.svg`;
    }
  };
  return (
    <>
      <div className="board">
        {wrap_none ? "" : <Search_Wrap query={query} />}
        <div className="borad_list">
          {ListData?.posts?.map((item, index) => (
            <div className="list_item" key={index}>
              <div className="thumnail">
                {item.post_video === "" ? (
                  item.post_thumbnail === "" ? (
                    <img src="images/image.svg" className="thumnail_noimage" />
                  ) : (
                    <img src={item.post_thumbnail} />
                  )
                ) : (
                  <img src={youturegEXP(item.post_video)} />
                )}
              </div>
              {/* 썸네일끝 */}
              <div className="list">
                <Link
                  to={withSearchParams(`/community`, {
                    set: { mode: "read", post_id: item.post_id },
                  })}
                >
                  {" "}
                  <div className="board_title">
                    {item.post_notice ? <span>[공지]</span> : ""}
                    {item.post_title}
                    {query.brd_id === "2" ? "" : <span> [{item.post_comment}]</span>}
                  </div>
                </Link>
                <div className="content_wrap">
                  {/* <span className="category">{item.Board.brd_key}</span> */}
                  <span className="category">{RelativeTime(item.createdAt)}</span>
                  <span>{item.user_name}</span>
                </div>
              </div>
              <div className="readlike">
                <div>
                  <i className="far fa-heart"></i> {item.post_like}
                </div>
                <div className="like_eye">
                  <i className="far fa-eye"></i> {item.post_read}
                </div>
              </div>
            </div>
          ))}
        </div>
        <Pagination pageTotal={pageTotal} currPage={currPage} getPage={getPage} list_size={list_size} />
      </div>
    </>
  );
};

export default BoardList;

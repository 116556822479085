import React, { useMemo, useRef, useState } from "react";
import axios from "axios";
// import classNames from "classnames";
import ReactQuill, { Quill } from "react-quill";
import ImageResize from "quill-image-resize";
import "react-quill/dist/quill.snow.css";
import QuillImageDropAndPaste from "quill-image-drop-and-paste";
import { useLocation } from "react-router-dom";
import queryString from "query-string";
import htmlEditButton from "quill-html-edit-button";

Quill.register("modules/ImageResize", ImageResize);
Quill.register("modules/imageDropAndPaste", QuillImageDropAndPaste);
Quill.register("modules/htmlEditButton", htmlEditButton);
const message_text_upload = "upload-error";
const message_text_upload_detail = "upload-error-detail";
const message_text_url = "upload-url-error";
const message_text_url_image = "upload-url-image-error";

const QEditor = ({ setdeleteList, deleteList, setcontent, content, setpost_video, local_user_no }) => {
  const location = useLocation(); //바뀐 부분
  const query = queryString.parse(location.search);
  const imgregExp = /<img[^>]*src=[\"']?([^>\"']+)[\"']?[^>]*>/i;
  const [video_id, setvideo_id] = useState("");
  const quillRef = useRef();
  const fileRef = useRef();
  const image_upload_click = () => fileRef.current.click();
  const imageHandler = (event) => {
    const file = event.target.files; // 데이터 만들어준다.
    const formData = new FormData();
    Object.values(file).forEach((file) => formData.append("imgFile", file));
    axios
      .post(process.env.REACT_APP_UPLOAD_URL + "upload", formData, {
        headers: {
          id: local_user_no,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          setdeleteList(() => [...deleteList, response.data]);
          const IMG_URL = process.env.REACT_APP_IMGURL + response.data.file_server;
          const editor = quillRef.current.getEditor(); // 에디터 객체 가져오기
          const range = editor.getSelection()?.index;
          if (typeof range !== "number") return;
          editor.setSelection(range, 1);
          editor.clipboard.dangerouslyPasteHTML(range, `<img src=${IMG_URL} alt="image" />`);
        } else {
          //   message.warning(message_text_upload);
          alert(message_text_upload);
        }
        event.target.value = "";
      })
      .catch((error) => {
        console.log(error);
        alert(message_text_upload_detail);
      });
  };

  const urlcheck = (e) => {
    setvideo_id(e.target.value);
    const regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
    const match = e.target.value.match(regExp);
    // console.log(match && match[7].length === 11 ? match[7] : false);
    if (match && match[7].length === 11) {
      setpost_video(e.target.value);
      const insert_url = `https://www.youtube.com/embed/${match[7]}`;
      const editor = quillRef.current.getEditor();
      const range = editor.getSelection();
      editor.insertEmbed(range, "video", insert_url);
    } else if (e.target.value.length > 60) {
      alert(message_text_url);
    }
  };

  const pasteHandler = (imageDataUrl, type, imageData) => {
    const file = imageData.toFile();
    const formData = new FormData();
    formData.append("imgFile", file);
    axios
      .post(process.env.REACT_APP_UPLOAD_URL + "upload", formData, {
        headers: {
          id: local_user_no,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          setdeleteList(() => [...deleteList, response.data]);
          const IMG_URL = process.env.REACT_APP_IMGURL + response.data.file_server;
          const editor = quillRef.current.getEditor(); // 에디터 객체 가져오기
          const range = editor.getSelection()?.index;
          if (typeof range !== "number") return;
          editor.clipboard.dangerouslyPasteHTML(range, `<img src=${IMG_URL} alt="image" />`);
        } else {
          alert(message_text_upload);
        }
      })
      .catch((error) => {
        alert(message_text_upload_detail);
      });
  };
  // useMemo를 사용하지 않으면, 키를 입력할 때마다, imageHandler 때문에 focus가 계속 풀리게 됩니다.
  const modules = useMemo(() => {
    return {
      toolbar: {
        theme: "snow",
        container: [
          [
            { header: [1, 2, 3, false] },
            "bold",
            "underline",
            // "italic",
            "strike",
            {
              color: ["#001F3F", "#FF0000", "#0074D9", "#2ECC40", "#FF851B", "#85144B", "#F012BE"],
            },
          ],
          [{ list: "ordered" }, { list: "bullet" }],
          ["image", "link"],
        ],

        handlers: {
          image: image_upload_click,
          link: function (value) {
            const that = quillRef.current.getEditor();
            const tooltip = that.theme.tooltip;
            const input = tooltip.root.querySelector("input[data-link]");
            input.dataset.link = "https://www.google.com";
            input.placeholder = "ex) https://www.google.com/";
            input.dataset.lpignore = true;
            if (value) {
              const range = that.getSelection();
              if (range == null || range.length === 0) {
                return;
              }
              let preview = that.getText(range);
              if (/^\S+@\S+\.\S+$/.test(preview) && preview.indexOf("mailto:") !== 0) {
                preview = `mailto:${preview}`;
              }
              const { tooltip } = that.theme;
              tooltip.edit("link", preview);
            } else {
              that.format("link", false);
            }
          },
        },
      },
      imageDropAndPaste: {
        handler: pasteHandler,
      },
      ImageResize: {
        modules: ["Resize", "DisplaySize"],
        handleStyles: {
          backgroundColor: "black",
          border: "none",
          color: "var(--main-background-color)",
        },
      },
      htmlEditButton: {
        // debug: true,
        msg: "Edit the content in HTML format",
        okText: "Ok",
        cancelText: "Cancel",
        buttonHTML: "HTML",
        buttonTitle: "Show HTML source",
        syntax: false,
        prependSelector: "div#myelement",
        editorModules: {},
      },
    };
  }, []);

  const formats = [
    "header",
    "bold",
    "underline",
    // "italic",
    "strike",
    "color",
    "image",
    "list",
    "bullet",
    "link",
    "video",
    "htmlEditButton",
  ];

  const setcontenthandle = (value, delta) => {
    /////////////////////이미지 삭제////////////
    const deltaList = delta.ops;
    const lastDelta = deltaList[deltaList.length - 1];

    if (lastDelta.delete) {
      deleteList.forEach((img, index) => {
        if (!value.includes(img.file_server)) {
          axios.delete(process.env.REACT_APP_UPLOAD_URL + "upload/" + img.file_id + `?id=${local_user_no}`);
          deleteList.splice(index, 1);
        }
      });
      if (!value.includes("ql-video")) {
        setvideo_id("");
      }
    }
    //////////////잘못된 첨부방식////////////////
    const lastimage = lastDelta.insert?.image;
    if (lastimage?.slice(0, 10) === "data:image") {
      // console.log("ADADASDASDASD");
      var element = document.getElementsByClassName("ql-editor");
      element[0].innerHTML = content;
      alert(message_text_url_image);
    }
    setcontent(value);
  };

  ///base64 파일형식으로 바꾸기
  function DataURIToBlob(dataURI) {
    const splitDataURI = dataURI.split(",");
    const byteString = splitDataURI[0].indexOf("base64") >= 0 ? atob(splitDataURI[1]) : decodeURI(splitDataURI[1]);
    const mimeString = splitDataURI[0].split(":")[1].split(";")[0];
    const ia = new Uint8Array(byteString.length);
    for (let i = 0; i < byteString.length; i++) ia[i] = byteString.charCodeAt(i);
    return new File([ia], "paste.png", { type: mimeString });
  }

  return (
    <>
      {/* <div className="Write_input_wrap">
        <input
          className={classNames("video_link_input", {
            hide: content.includes("ql-video"),
          })}
          value={video_id}
          onChange={urlcheck}
        ></input>
      </div> */}

      <ReactQuill
        theme="snow"
        placeholder="게시물을 작성해주세요"
        onChange={setcontenthandle}
        modules={modules}
        formats={formats}
        ref={quillRef}
        value={content}
      />
      <input
        ref={fileRef}
        onChange={imageHandler}
        type="file"
        accept="image/jpeg , image/png, image/gif"
        // multiple="multiple"
        style={{ display: "none" }}
      />
    </>
  );
};

export default QEditor;

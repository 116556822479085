import React, { useState, useEffect } from "react";

//pagetotal 총 페이지 갯수
//currPage 현재 페이지
//list_size 페이지당 게시글 갯수
//getPage 함수
const Pagination = React.memo(({ pageTotal, currPage, getPage, list_size }) => {
  const [blcokNum, setblcokNum] = useState(0);
  const maxPage = Math.ceil(pageTotal / list_size); // 마지막 페이지번호
  const pageLimit = 5; // 1~5까지 표시할 페이지 갯수
  const v = blcokNum * pageLimit;
  useEffect(() => {
    // console.log("블럭넘", Math.ceil(currPage / pageLimit) - 1);
    setblcokNum(Math.ceil(currPage / pageLimit) - 1);
  }, [pageTotal]);
  //[currPage]로 바꿔주면 밑에 함수에 if문 다뺄수있음 근데 이동할때마다 이펙트 실행됌

  const PArr = []; //빈배열 생성
  if (pageTotal !== 0) {
    for (var i = 1; i <= maxPage; i++) {
      PArr.push(i);
    }
  }
  const iArr = PArr.slice(v, pageLimit + v);

  const pageHandle = (n) => {
    // console.log(n);
    getPage(n);
  };

  const next = () => {
    if (currPage >= maxPage) return;
    if (pageLimit * (blcokNum + 1) < currPage + 1) {
      setblcokNum(blcokNum + 1);
    }
    getPage(currPage + 1);
  };

  const prevPage = () => {
    if (currPage <= 1) return;
    if (currPage - 1 <= pageLimit * blcokNum) {
      setblcokNum(blcokNum - 1);
    }
    getPage(currPage - 1);
  };
  const firstPage = () => {
    setblcokNum(0);
    getPage(1);
  };
  const LastPage = () => {
    setblcokNum(Math.ceil(maxPage / pageLimit) - 1);
    getPage(maxPage);
  };
  return (
    <div className="commu_paging">
      <button className="paging_btn start" onClick={firstPage}>
        {"<<"}
      </button>
      <button className="paging_btn prev" onClick={prevPage}>
        {"<"}
      </button>
      {iArr.map((n) => (
        <button className="paging_btn" key={n} onClick={() => pageHandle(n)} id={parseInt(currPage) === n ? "buttonON" : ""}>
          {n}
        </button>
      ))}
      <button className="paging_btn next" onClick={next}>
        {">"}
      </button>
      <button className="paging_btn end " onClick={LastPage}>
        {">>"}
      </button>
    </div>
  );
});

export default Pagination;
